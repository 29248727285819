



























































































































































import { PropType, Ref, computed, defineComponent } from '@vue/composition-api';
import { AnonymisationField, IntervalAnonymisationField } from '../../types';
import HandleNullValuesFieldSection from './HandleNullValuesFieldSection.vue';
import PreviewAnonymisationRules from './PreviewAnonymisationRules.vue';
import { MappingFieldConfiguration } from '../../types/typings';
import { useQuasiIdentifierAnonymisation } from '../../composable';
import { ValidationProvider, extend } from 'vee-validate';
import { InputErrorIcon } from '@/app/components';
import { isEmpty, isNil } from 'ramda';
import { greaterThanValidator, notEqualToValidator, requiredValidator } from '@/app/validators';
import AnonymisationNilValue from './AnonymisationNilValue.vue';
import { TrashIcon } from '@vue-hero-icons/outline';
import IntervalRule from './rules/IntervalRule.vue';

extend('required', requiredValidator);
extend('not_equal_to', notEqualToValidator);
extend('greater_than', greaterThanValidator);

export default defineComponent({
    name: 'IntervalAnonymisationField',
    model: {
        prop: 'field',
        event: 'changed',
    },
    props: {
        field: {
            type: Object as PropType<IntervalAnonymisationField>,
            required: true,
        },
        inEdit: { type: Boolean, default: false },
        mappingField: {
            type: Object as PropType<MappingFieldConfiguration & { originalTitle: string }>,
            required: true,
        },
        sample: { type: Array, required: true },
    },
    components: {
        HandleNullValuesFieldSection,
        PreviewAnonymisationRules,
        ValidationProvider,
        InputErrorIcon,
        AnonymisationNilValue,
        TrashIcon,
        IntervalRule,
    },
    setup(props, { emit }) {
        const selectedField: Ref<IntervalAnonymisationField> = computed({
            get: () => props.field,
            set: (updatedField: AnonymisationField) => {
                emit('changed', updatedField);
            },
        });
        const mappingFieldRef = computed(() => props.mappingField);
        const sampleRef = computed(() => props.sample);
        const { getSampleValue, baseRule, rules } = useQuasiIdentifierAnonymisation(
            selectedField,
            mappingFieldRef,
            sampleRef,
        );

        const setLevelInterval = (levelIndex: number, e: any) => {
            const value = e.target.value;
            selectedField.value.options.levels[levelIndex].interval =
                isNil(value) || isEmpty(value) ? null : Number(value);
        };

        const removeCustomLevel = (level: number) => {
            if (selectedField.value.options.levels.length === 1) {
                selectedField.value.options.levels = [{ interval: null }];
            } else {
                selectedField.value.options.levels.splice(level, 1);
            }
        };

        const addGeneralisationLevel = () => {
            selectedField.value.options.levels.push({ interval: null });
        };

        const minLevelValue = (index: number) => {
            if (
                selectedField.value.options.levels &&
                selectedField.value.options.levels.length > 1 &&
                index > 0 &&
                !isNil(selectedField.value.options.levels[index - 1].interval)
            ) {
                const interval: number = selectedField.value.options.levels[index - 1].interval as number;
                return interval + 1;
            }
            return 2;
        };

        const maxLevelValue = (index: number) => {
            if (selectedField.value.options.levels && selectedField.value.options.levels.length > 1) {
                if (
                    index < selectedField.value.options.levels.length - 1 &&
                    !isNil(selectedField.value.options.levels[index + 1].interval)
                ) {
                    const interval: number = selectedField.value.options.levels[index + 1].interval as number;
                    if (interval > 2) return interval - 1;
                }
            }
            return Number.MAX_VALUE;
        };

        const getExampleValue = (row: Record<string, any>, index: number): { from: number | null; to?: number } => {
            const value = getSampleValue(row);
            if (isNil(value)) return { from: null };
            if (index === 0) return { from: Number(value) };

            const level = rules.value[index - 1];
            const lowerBound = Math.floor(Number(value) / level.interval) * level.interval;
            return { from: lowerBound, to: lowerBound + level.interval };
        };

        const getIntervalErrorMessage = (value: number | undefined | null, index?: number) => {
            if (!isNil(value)) {
                if (value === 0) return 'The interval value cannot be 0';
                if (!isNil(index)) {
                    const minValue = minLevelValue(index);
                    const maxValue = maxLevelValue(index);
                    if (value < minValue || (value > maxValue && maxValue > 1)) {
                        return 'The interval value of this level should be higher than the previous level value and lower than the next level value, if applicable';
                    }
                }
            }
            return null;
        };

        const numberOfDecimals = (interval: number | undefined) => {
            const splitInterval = `${interval}`.split('.');
            if (splitInterval.length > 1) return splitInterval[1].length;

            return 0;
        };

        const getIntervalLevelValidationRules = (levelIndex: number) => {
            const validationRules = { required: true, not_equal_to: 0 };

            // If the level is not the first one, the interval should be greater than the previous level
            if (levelIndex > 0) {
                validationRules['greater_than'] = selectedField.value.options.levels[levelIndex - 1].interval;
            }

            return validationRules;
        };

        return {
            selectedField,
            rules,
            baseRule,
            setLevelInterval,
            removeCustomLevel,
            addGeneralisationLevel,
            getExampleValue,
            getIntervalErrorMessage,
            getIntervalLevelValidationRules,
            numberOfDecimals,
        };
    },
});
