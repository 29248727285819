var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[(_vm.standardsMapping && _vm.standardsMapping.length)?_c('div',{staticClass:"mb-2"},[_c('Scrollbar',{staticClass:"w-full h-40",class:{ 'pointer-events-none': _vm.editStandardsMapping }},_vm._l((_vm.standardsMapping),function(standardMapping,index){return _c('div',{key:index,staticClass:"flex w-full p-2 rounded",class:{
                    'cursor-pointer  hover:bg-primary-100 ': !_vm.openStandardMappingFormToggle && !_vm.readOnly,
                    'pointer-events-none': _vm.readOnly || _vm.openStandardMappingFormToggle,
                    'bg-primary-100': _vm.editStandardsMapping && index === _vm.editingStandardIdx,
                },on:{"mouseenter":function($event){_vm.hoveredItemId = index},"mouseleave":function($event){_vm.hoveredItemId = null},"click":function($event){return _vm.editStandard(index, standardMapping)}}},[_c('div',{staticClass:"flex items-center w-full space-x-1 leading-5 text-neutral-700"},[_c('ChevronRightIcon',{staticClass:"w-4 h-4 mr-2 text-primary-700"}),_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Standard'),expression:"'Standard'"}],staticClass:"block truncate",attrs:{"title":standardMapping.standard}},[_vm._v(" "+_vm._s(standardMapping.standard)+" ")]),_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Version'),expression:"'Version'"}],staticClass:"block text-sm truncate text-primary-700",attrs:{"title":standardMapping.version}},[_vm._v(" "+_vm._s(standardMapping.version)+" ")])],1),(!_vm.readOnly && _vm.hoveredItemId === index)?_c('span',{staticClass:"flex items-center"},[_c('ConfirmButton',{staticClass:"flex items-center my-auto",on:{"confirmed":function($event){return _vm.removeStandardsMapping(index)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"my-auto cursor-pointer text-neutral-500 hover:text-neutral-700",attrs:{"title":"Remove"}},[_c('TrashIcon',{staticClass:"w-4 h-4"})],1)]},proxy:true},{key:"confirm",fn:function(){return [_c('div',{staticClass:"my-auto text-orange-800 cursor-pointer hover:text-orange-900",attrs:{"title":"Click again to confirm deletion"}},[_c('ExclamationCircleIcon',{staticClass:"w-4 h-4 my-auto"})],1)]},proxy:true}],null,true)})],1):_vm._e()])}),0)],1):_vm._e(),(_vm.openStandardMappingFormToggle)?_c('validation-observer',{ref:"standardsMappingRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var invalid = ref.invalid;
return [_c('div',{staticClass:"p-3 rounded-md bg-primary-100 border-primary-100"},[_c('div',{staticClass:"flex justify-between w-full space-x-4"},[_c('validation-provider',{staticClass:"w-1/2",attrs:{"rules":"required|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('div',{staticClass:"mb-4 space-y-2 text-neutral-700"},[_c('span',{staticClass:"flex items-start text-xs font-bold tracking-wider capitalize"},[_vm._v("Standard"),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('This field is required'),expression:"'This field is required'"}],staticClass:"text-red-500"},[_vm._v("*")])]),_c('div',{staticClass:"flex w-full h-full"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.standardMappingInForm.standard),expression:"standardMappingInForm.standard",modifiers:{"trim":true}}],staticClass:"block w-full text-sm focus:ring-2 focus-within:z-10",class:{
                                    'border-red-500': errors.length,
                                },attrs:{"name":"Standard","type":"text","placeholder":"Enter a standard"},domProps:{"value":(_vm.standardMappingInForm.standard)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.standardMappingInForm, "standard", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),(errors.length || _vm.standardAlreadyExists)?_c('div',{staticClass:"mt-1 text-xs font-bold text-red-600"},[_vm._v(" "+_vm._s(_vm.standardAlreadyExists ? "'Standard' with this version already exists" : errors[0])+" ")]):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"w-1/2",attrs:{"id":"version","name":"version","rules":{ required: !!_vm.standardMappingInForm.standard, max: 64 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('div',{staticClass:"mb-4 space-y-2 text-neutral-700"},[_c('span',{staticClass:"flex items-start text-xs font-bold tracking-wider capitalize"},[_vm._v("Version"),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('This field is required'),expression:"'This field is required'"}],staticClass:"text-red-500"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.standardMappingInForm.version),expression:"standardMappingInForm.version",modifiers:{"trim":true}}],staticClass:"block w-full text-sm focus:ring-2",class:{
                                'border-red-500': errors.length,
                            },attrs:{"name":"Version","type":"text","placeholder":"Enter a version"},domProps:{"value":(_vm.standardMappingInForm.version)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.standardMappingInForm, "version", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(errors.length)?_c('div',{staticClass:"mt-1 text-xs font-bold text-red-600"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"flex items-center justify-end space-x-2"},[_c('TwButton',{attrs:{"size":"xs","color":"neutral"},on:{"click":_vm.closeStandardMappingForm}},[_c('span',{staticClass:"tracking-wide"},[_vm._v("Cancel")])]),_c('TwButton',{attrs:{"size":"xs","color":"secondary","disabled":invalid || _vm.standardAlreadyExists || _vm.noChange},on:{"click":function($event){return _vm.addOrUpdateStandardMapping()}}},[_c('span',{staticClass:"tracking-wide"},[_vm._v(_vm._s(_vm.editStandardsMapping ? 'Update' : 'Add'))])])],1)])]}}],null,false,3381054430)}):_vm._e(),(!_vm.openStandardMappingFormToggle && !_vm.readOnly)?_c('button',{staticClass:"flex flex-row p-1 space-x-1 text-sm font-semibold rounded cursor-pointer text-primary-700 hover:text-primary-900 focus:outline-none focus:ring",on:{"click":function($event){return _vm.openStandardMappingForm(true)}}},[_c('PlusIcon',{staticClass:"w-4 h-4 my-auto"}),_c('div',[_vm._v("Add a Standard")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }